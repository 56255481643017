import React, { useEffect, useState } from 'react';
import { getSpecificDevice } from 'api/devices';
import { Box, Paper, Typography, Grid, Alert, CircularProgress } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import InfoIcon from '@mui/icons-material/Info';

interface DeviceData {
  id: string;
  device_family_name: string;
  adding_time: string;
  activation_time: string;
  description: string;
  device_api_key: string;
  device_activation_key: string;
  last_request_time: Date;
}

interface DeviceSummaryProps {
  device_id: string;
}

export const DeviceSummary: React.FC<DeviceSummaryProps> = ({ device_id }) => {
  const [deviceData, setDeviceData] = useState<DeviceData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await getSpecificDevice(device_id);
      setDeviceData(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching device data:', error);
      setError('Ошибка при загрузке данных устройства. Пожалуйста, попробуйте позже.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [device_id]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Paper elevation={3} >
        <Box display="flex" alignItems="center" borderBottom={1} pb={1} mb={1} p={2}>
            <DeviceHubIcon color="primary" />
            <Typography variant="h5" component="h2" ml={1}>
              Параметры устройства
            </Typography>
          </Box>
        <Box sx={{ padding: '1rem', marginBottom: 6 }}>
        <Alert severity="error">{error}</Alert>
        </Box>
        </Paper>
      </Box>
    );
  }

  if (!deviceData) {
    return (
      <Box>
        <Alert severity="warning">Данные устройства не найдены.</Alert>
      </Box>
    );
  }

  return (
    <Box>
      <Paper elevation={3}>
        <Box p={2}>
          {/* Header */}
          <Box display="flex" alignItems="center" borderBottom={1} pb={1} mb={2}>
            <DeviceHubIcon color="primary" />
            <Typography variant="h5" component="h2" ml={1}>
              Параметры устройства
            </Typography>
          </Box>

          {/* Device Details */}
          <Box display="flex" alignItems="center" pb={1}>
            <InfoIcon color="primary" />
            <Typography variant="h6" component="h2" ml={1}>
              Общая информация
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" color="textSecondary">
                Device ID
              </Typography>
              <Typography variant="body1">{deviceData.id}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" color="textSecondary">
                Имя семейства
              </Typography>
              <Typography variant="body1">{deviceData.device_family_name}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" color="textSecondary">
                Время последнего ответа устройства
              </Typography>
              <Typography variant="body1">
                {new Date(deviceData.last_request_time).toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" color="textSecondary">
                Время добавления
              </Typography>
              <Typography variant="body1">
                {new Date(deviceData.adding_time).toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" color="textSecondary">
                Время активации
              </Typography>
              <Typography variant="body1">
                {new Date(deviceData.activation_time).toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" color="textSecondary">
                Описание
              </Typography>
              <Typography variant="body1">{deviceData.description}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};