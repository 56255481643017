import { Box } from '@mui/system';
import DevicesList from 'components/DevicesList';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { apiAddress } from 'constants/apiAddress';
import FileDownloadIcon from '@mui/icons-material/FileDownload';


export const DevicesListManagement = () => {
  return (
    <Box sx={{ 
      margin: {
        xs: '0.5rem', // margin for mobile devices
        sm: '1.5rem', // margin for small devices
        md: '2rem'   // margin for larger devices
      }
    }}>
      <h1>Управление устройствами</h1>
      <DevicesList />
    </Box>
  );
};