import { useState } from 'react';
import { Box, Paper, TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { useSearchParams } from 'react-router-dom';
import { DeviceMetricsPlots } from 'components/DeviceMetricsPlots';
import { DeviceSummary } from 'components/DeviceSummary';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import { ExportDeviceData } from 'components/ExportDeviceData';
import { Typography } from '@mui/material';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { DeviceAnalytics } from 'components/DeviceAnalytics';
import { Divider } from '@mui/material';
import { RemoveDevice } from 'components/RemoveDevice';

export const DevicePage = () => {
  const [searchParams] = useSearchParams();
  const device_id = searchParams.get('device_id') as string;

  // Current date
  const currentDate = new Date();
  // Date 7 days ago
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  // State for date pickers
  const [startDate, setStartDate] = useState<Date>(sevenDaysAgo);
  const [endDate, setEndDate] = useState<Date>(currentDate);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ 
      margin: {
        xs: '0.5rem', // margin for mobile devices
        sm: '1.5rem', // margin for small devices
        md: '2rem'   // margin for larger devices
      }
    }}>
      <h1>Страница устройства</h1>
      <Box marginBottom={4}>
         <DeviceSummary device_id={device_id} />
        </Box>
        <Paper elevation={2}>
        <Paper elevation={2} sx={{ padding: '1rem', marginBottom: 6 }}>
          <Box display="flex" alignItems="center" borderBottom={1} pb={1} mb={3}>
              <AutoGraphIcon color="primary" />
              <Typography variant="h5" component="h2" ml={1}>
                Почасовая аналитика
              </Typography>
            </Box>
          <DeviceAnalytics device_id={device_id} />
        </Paper>
        </Paper>
        <Divider sx={{bgcolor: "primary.main", borderBottomWidth:"4px"}}/>
        <Paper elevation={2} sx={{marginTop: 6}}>
        <Paper elevation={4} sx={{ padding: '1rem' }}>
          <Box display="flex" alignItems="center" borderBottom={1} pb={1} mb={3}>
              <AutoGraphIcon color="primary" />
              <Typography variant="h5" component="h2" ml={1}>
                Полные показания
              </Typography>
            </Box>
          <DeviceMetricsPlots device_id={device_id} />
        </Paper>
        </Paper>
      <Paper elevation={4} sx={{ padding: '1rem', marginTop: 6 }}>
          <Box display="flex" alignItems="center" borderBottom={1} pb={1} mb={3}>
              <AutoGraphIcon color="primary" />
              <Typography variant="h5" component="h2" ml={1}>
                Экспорт данных
              </Typography>
            </Box>
          <ExportDeviceData device_id={device_id} />
      </Paper>
      <Paper elevation={4} sx={{ padding: '1rem', marginTop: 6 }}>
          <Box display="flex" alignItems="center" borderBottom={1} pb={1} mb={3}>
              <AutoGraphIcon color="primary" />
              <Typography variant="h5" component="h2" ml={1}>
                Другое
              </Typography>
            </Box>
          <RemoveDevice device_id={device_id} />
      </Paper>
        </Box>
    </LocalizationProvider>
  );
};