import React, { useState } from 'react';
import { Button, TextField, Tooltip, Box, Alert } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { registerNewDevice } from 'api/devices';

interface RegisterDeviceFormProps {
  onSuccess: () => void;
}

const RegisterDeviceForm: React.FC<RegisterDeviceFormProps> = ({ onSuccess }) => {
  const [name, setName] = useState<string>('');
  const [deviceActivationKey, setDeviceActivationKey] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const success = await registerNewDevice(deviceActivationKey);
    
    if (success) {
      await onSuccess();
      // Reset form fields
      setDeviceActivationKey('');
      setName('');
      setError(null); // Clear any previous error
    } else {
      setError('Ошибка активации устройства. Пожалуйста, проверьте ключ активации.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && (
        <Alert severity="error" sx={{ marginBottom: '1rem' }}>
          {error}
        </Alert>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem', width: '100%' }}>
        <TextField
          id="name"
          label="Пользовательское имя устройства"
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
          required
          sx={{ flexGrow: 1 }} // Ensure TextField takes available space
        />
        <Tooltip title="Отображаемое имя устройства">
          <InfoIcon sx={{ marginLeft: 1, cursor: 'pointer' }} />
        </Tooltip>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem', width: '100%' }}>
        <TextField
          id="device_activation_key"
          label="Ключ активации"
          fullWidth
          value={deviceActivationKey}
          onChange={(event) => setDeviceActivationKey(event.target.value)}
          required
          sx={{ flexGrow: 1 }} // Ensure TextField takes available space
        />
        <Tooltip title="Ключ активации из комплекта поставки">
          <InfoIcon sx={{ marginLeft: 1, cursor: 'pointer' }} />
        </Tooltip>
      </Box>

      <Button type="submit" variant="contained" color="primary">
        Активировать устройство
      </Button>
    </form>
  );
};

export default RegisterDeviceForm;