import React, { useEffect, useState } from 'react';
import { Box, Button, Paper, Typography, CircularProgress, Grid, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import { Link } from 'react-router-dom';
import { getAllDevices } from 'api/devices';
import RegisterDeviceForm from './RegisterDeviceForm';
import { useTheme } from '@mui/material';

interface DeviceData {
  id: string;
  device_family_name: string;
  adding_time: string;
  activation_time: string;
  is_active: number;
  description: string;
  last_request_time: Date;
}

const DevicesList: React.FC = () => {
  const [data, setData] = useState<DeviceData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false); // State for dialog
  const theme = useTheme();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getAllDevices();
      setData(response);
    } catch (error) {
      console.error('Error fetching devices:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleRegisterSuccess = async () => {
    await fetchData(); // Refresh device list after successful registration
    handleDialogClose(); // Close the dialog
  };

  const renderAnalyticsButton = (id: string) => (
    <Button
      variant="contained"
      color="primary"
      startIcon={<InfoIcon />}
      component={Link}
      to={`/device-data?device_id=${id}`}
      disabled={isLoading}
    >
      Аналитика
    </Button>
  );

  return (
    <Box>
      <Paper sx={{ padding: 3, display: 'flex', alignItems: 'center', marginBottom: 3 }} elevation={5}>
        <Button variant="contained" color="primary" onClick={handleDialogOpen}>
          Добавить устройство
        </Button>
      </Paper>
      <Typography variant='h4' sx={{ marginBottom: 3 }}>Ваши устройства</Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={2}>
          {data.map((device) => (
            <Grid item xs={12} sm={6} md={4} key={device.id}>
              <Paper sx={{ padding: 2, 
                          display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between', borderLeft: `6px solid ${theme.palette.primary.main}`}}>
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <DeviceUnknownIcon color="primary" sx={{ marginRight: 1 }} />
                    <Typography variant="h6">{device.device_family_name}</Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">Device ID</Typography>
                      <Typography variant="body1">{device.id}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">Имя семейства</Typography>
                      <Typography variant="body1">{device.device_family_name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">Время последнего ответа устройства</Typography>
                      <Typography variant="body1">{new Date(device.last_request_time).toLocaleString()}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">Время добавления</Typography>
                      <Typography variant="body1">{new Date(device.adding_time).toLocaleString()}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">Время активации</Typography>
                      <Typography variant="body1">{new Date(device.activation_time).toLocaleString()}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">Описание</Typography>
                      <Typography variant="body1">{device.description}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                    {renderAnalyticsButton(device.id)}
                    </Grid>
                  </Grid>
                </Box>
                
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
      
      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="md">
        <DialogTitle>Добавить устройство</DialogTitle>
        <DialogContent  style={{ paddingTop: '16px' }}>
          <RegisterDeviceForm onSuccess={handleRegisterSuccess} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DevicesList;