import { apiAddress } from "constants/apiAddress";
import { LocalStorageKeys } from 'constants/localStorageKeys';


export const getAllDevices = async () =>
    await fetch(`${apiAddress}/contractors/devices-list`, {
        method: "GET",
        headers:{
            "Authorization":`Bearer ${localStorage.getItem(LocalStorageKeys.JWT_TOKEN)}`
            }})
        .then((response) => response.json())
        .catch((reason) => console.error(reason));


export const getSpecificDevice = async (device_id: string) =>
    await fetch(`${apiAddress}/contractors/devices-list/${device_id}`, {
        method: "GET",
        headers:{
            "Authorization":`Bearer ${localStorage.getItem(LocalStorageKeys.JWT_TOKEN)}`
            }})
        .then((response) => response.json());

        
export const registerNewDevice = async (device_activation_key: string) =>
    await fetch(`${apiAddress}/contractors/register-device?device_activation_key=${device_activation_key}`, {
        method: 'POST',
        headers:{
        "Authorization":`Bearer ${localStorage.getItem(LocalStorageKeys.JWT_TOKEN)}`,
        "Content-Type": "application/json"
        }
    })
    .then((response) => response.ok ? response.ok : false)
    .catch((reason) => console.error(reason));


export const deleteDevice = async (device_id: string) =>
    await fetch(`${apiAddress}/contractors/devices-list/${device_id}`, {
        method: 'DELETE',
        headers:{
        "Authorization":`Bearer ${localStorage.getItem(LocalStorageKeys.JWT_TOKEN)}`
        }
    })
    .then((response) => response.ok ? response.ok : false)
    .catch((reason) => console.error(reason));