import { apiAddress } from "constants/apiAddress";
import { LocalStorageKeys } from 'constants/localStorageKeys';


export const getDeviceHourlyMetrics = async (device_id: string, start_date: Date, end_date: Date) =>
    await fetch(`${apiAddress}/contractors/metrics/hourly/${device_id}/aggregate?start_date=${start_date.toISOString().split("T")[0]}&end_date=${end_date.toISOString().split("T")[0]}`, {
        method: "GET",
        headers:{
            "Authorization":`Bearer ${localStorage.getItem(LocalStorageKeys.JWT_TOKEN)}`,
            "Content-Type": "application/json"
            }})
        .then((response) => response.json());