import React, { useState } from 'react';
import { TextField, Button, Grid, Typography, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface FormData {
  username: string;
  password: string;
}

interface LoginFormProps {
  onSubmit: (username: string, password: string) => Promise<boolean>;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const [formData, setFormData] = useState<FormData>({
    username: '',
    password: '',
  });
  const [loginError, setLoginError] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const loginSuccessful = await onSubmit(formData.username, formData.password);
    if (loginSuccessful) {
      navigate('/devices-list');
    } else {
      setLoginError(true);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} marginBottom={2}>
          <Typography variant="h5" align="center">
            Кабинет пользователя
          </Typography>
          <Typography variant="h5" align="center">
            Войти в аккаунт
          </Typography>
        </Grid>
        {loginError && (
          <Grid item xs={12}>
            <Alert severity="error" onClose={() => setLoginError(false)}>
              Неверный логин или пароль
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            name="username"
            label="Имя пользователя"
            type="username"
            value={formData.username}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} marginBottom={2}>
          <TextField
            name="password"
            label="Пароль"
            type="password"
            value={formData.password}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Войти
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;