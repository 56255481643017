import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Use useNavigate for redirection

import { deleteDevice } from 'api/devices';

interface RemoveDeviceProps {
  device_id: string;
}

export const RemoveDevice: React.FC<RemoveDeviceProps> = ({ device_id }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleDeleteClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteDevice(device_id); // Call the delete API
      navigate('/devices-list'); // Redirect to /home
    } catch (error) {
      console.error("Error deleting device:", error);
      // Handle error appropriately, e.g., show an alert
    }
  };

  return (
    <>
      <Box>
        <Button variant="contained" color="error" onClick={handleDeleteClick}>
          Удалить устройство
        </Button>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Подтверждение удаления</DialogTitle>
        <DialogContent>
          <Typography>Удалить устройство из аккаунта? Вы всегда можете восстановить доступ, воспользовавшись ключом активации</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Отмена
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};