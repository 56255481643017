import { apiAddress } from 'constants/apiAddress';

export const handleLogin = async (username: string, password: string) => {
  try {
    const response = await fetch(`${apiAddress}/contractors/token`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'password',
        username: username,
        password: password,
      }).toString(),
    });

    if (response.ok) {
      const data = await response.json();
      return data.access_token;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};


export const getUserData = async (token: string) =>
    await fetch(`${apiAddress}/user/current`, {
        method: "GET",
        headers: {
            "Authorization" : `Bearer ${token}`
        }})
        .then((response) => response.json())
        .catch((reason) => console.error(reason));
